import React from 'react';

const typebot = () => {
  return (
    <div style={{ width: '100%', height: '100vh', overflow: 'hidden' }}>
      <iframe
        src="https://bot-builder.dynatecnologia.com/pt-BR/typebots"
        title="Bot Builder"
        width="100%"
        height="100%"
        style={{ border: 'none' }}
      />
    </div>
  );
};

export default typebot;